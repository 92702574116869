<template>
  <div class="game-error-modal" data-t="game-error-modal" :class="platform">
    <StDrawerHeader v-if="platform === 'mobile'" class="drawer-header" />
    <StIcon name="circle-cross" class="icon" :size="48" />
    <h2 class="title">
      {{ title }}
    </h2>
    <p class="info">
      {{ t('gameErrors.tryLater') }}
    </p>
    <StButton
      :label="t('gameErrors.close')"
      size="l"
      block
      type="gray"
      class="button"
      data-t="close-button"
      @click="closeModal"
    />
  </div>
</template>

<script setup lang="ts">
const { code } = defineProps<{ code?: string }>()
const { t } = useI18n()

const route = useRoute()
const router = useRouter()

function closeModal() {
  router.push({ query: { ...route.query, modal: undefined } })
}

const { platform } = usePlatform()

const title = computed(() =>
  code === 'ACTION_FORBIDDEN_BY_TAG'
    ? t('gameErrors.gameForbiddenTitle')
    : t('gameErrors.gameNotWork'),
)
</script>

<style scoped>
.icon {
  color: var(--text-tertiary);
}

.game-error-modal {
  overflow: hidden;

  padding: var(--spacing-500) var(--spacing-600);

  text-align: center;

  background: url('./bg.jpg');
  background-size: cover;
  border-radius: var(--border-radius-200) var(--border-radius-200) 0 0;

  &.desktop {
    width: 480px;
  }

  &.mobile {
    padding: var(--spacing-400) var(--spacing-200) var(--spacing-200);
  }
}

.drawer-header {
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
}

.title {
  margin: 0;
  margin-top: var(--spacing-300);
  font: var(--desktop-text-xl-semibold);
}

.info {
  margin: 0;
  margin-top: var(--spacing-100);

  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
  text-align: center;
}

.button {
  margin-top: var(--spacing-300);
}

.mobile {
  .title {
    margin-top: var(--spacing-200);
    font: var(--mobile-title-2-semibold);
  }

  .info {
    margin-top: var(--spacing-050);
    font: var(--mobile-text-content-regular);
  }
}
</style>
